import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

// Externals
import { Box, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useRouter } from 'next/router';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';

// Component
import Scrollbar from 'components/legacy/components/Scrollbar';

interface StepSignInProps {
	handleBlur: any;
	handleChange: any;
	values: any;
	setStep: any;
}

const StepSignIn: FC<StepSignInProps> = (props) => {
	const auth = getAuth();
	const router = useRouter();
	const itemsRef = useRef([]);

	const [signIn, setSignIn] = useState<boolean>(false);

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const togglePasswordVisibility = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	useEffect(() => {
		itemsRef.current = itemsRef.current.slice(0, 6);
	}, []);

	const handleSignIn = async (email: string, password: string): Promise<void> => {
		try {
			setSignIn(true);

			await signInWithEmailAndPassword(auth, email, password);
			router.push('/city');
		} catch (err) {
			switch (err.code) {
				case 'auth/user-not-found':
					toast.error(t`Impossible de se connecter. Vérifier votre email et mot de passe`);
					break;
				case 'auth/wrong-password':
					toast.error(t`Impossible de se connecter. Vérifier votre email et mot de passe`);
					break;
				case 'auth/invalid-login-credentials':
					toast.error(t`Impossible de se connecter. Vérifier votre email et mot de passe`);
					break;
				case 'auth/invalid-email':
					toast.error(t`Impossible de se connecter. Vérifier votre email et mot de passe`);
					break;

				case 'auth/too-many-requests':
					toast.error(
						t`L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Veuillez contacter votre support technique ou réessayer plus tard.`
					);
					break;
				default:
					toast.error(t`Une erreur inconnue est survenue lors de la connexion. Veuillez réessayer ultérieurement.`);
					break;
			}
		} finally {
			setSignIn(false);
		}
	};

	return (
		<Scrollbar options={{ suppressScrollX: true }}>
			<Box
				sx={{
					p: 4
				}}
			>
				<TextField
					fullWidth={true}
					label={t`Adresse e-mail*`}
					margin="normal"
					name="email"
					onChange={props.handleChange}
					type="email"
					value={props.values.email}
					variant="outlined"
				/>
				<TextField
					fullWidth={true}
					InputProps={{
						endAdornment: (
							<InputAdornment
								onClick={() => togglePasswordVisibility()}
								sx={{ cursor: 'pointer', pl: 2 }}
								position="start"
							>
								{isPasswordVisible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
							</InputAdornment>
						)
					}}
					label={t`Mot de passe*`}
					margin="normal"
					name="password"
					onBlur={props.handleBlur}
					onChange={props.handleChange}
					onKeyDown={(e) => {
						if (e.key === 'Enter') handleSignIn(props.values.email, props.values.password);
					}}
					type={isPasswordVisible ? 'text' : 'password'}
					value={props.values.password}
				/>
				<Box sx={{ pt: 2 }}>
					<LoadingButton
						color="primary"
						disabled={props.values.email === '' || props.values.password === ''}
						fullWidth={true}
						loading={signIn}
						onClick={() => handleSignIn(props.values.email, props.values.password)}
						type="submit"
						variant="contained"
					>
						{t`Se connecter`}
					</LoadingButton>
					<Typography align="right" color="gray" sx={{ mt: 2 }} variant="subtitle1">
						<Link
							color="gray"
							onClick={() => props.setStep('reset-password')}
							sx={{ cursor: 'pointer' }}
							underline="hover"
							variant="subtitle1"
						>
							{t`Mot de passe oublié ?`}
						</Link>
					</Typography>
				</Box>
			</Box>
		</Scrollbar>
	);
};

export default StepSignIn;

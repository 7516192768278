import { FC, ReactNode, useState } from 'react';
import { useSigninCheck } from 'reactfire';

// Externals
import { Box, Button, experimentalStyled, NoSsr, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { t } from '@lingui/macro';
import Link from 'next/link';
import { useRouter } from 'next/router';

import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';

// Components
import FormSignIn from 'components/legacy/pages/authentication/actions/signin';

interface OnboardingLayoutProps {
	children?: ReactNode;
}

const OnboardingLayout: FC<OnboardingLayoutProps> = ({ children }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const router = useRouter();

	return (
		<>
			<NoSsr>
				<LayoutHeader />
			</NoSsr>

			{isMobile || isMd ? (
				<div
					style={{
						backgroundColor:
							router.asPath === '/'
								? '#727BFE'
								: router.asPath === '/about-us' || router.asPath === '/functionalities'
								? '#FFF'
								: '#727BFE',

						display: 'flex',
						justifyContent: 'center',
						overflow: 'auto',
						height: '86%',
						width: '100%'
					}}
				>
					<div
						style={{
							display: 'flex',
							flex: '1 1 auto',
							overflow: 'auto'
						}}
					>
						<div
							style={{
								flex: '1 1 auto',
								height: '100%',
								position: 'relative',
								scrollBehavior: 'smooth',
								overflow: 'auto',
								WebkitOverflowScrolling: 'touch'
							}}
						>
							{children}
						</div>
					</div>
				</div>
			) : (
				<div
					style={{
						backgroundColor:
							router.asPath === '/'
								? '#727BFE'
								: router.asPath === '/about-us' || router.asPath === '/functionalities'
								? '#FFF'
								: '#727BFE',

						display: 'flex',

						justifyContent: 'center',
						height: '86%',
						width: '100%'
					}}
				>
					<div
						style={{
							display: 'flex',
							flex: '1 1 auto'
						}}
					>
						<div
							style={{
								flex: '1 1 auto',
								height: '100%',
								position: 'relative',
								scrollBehavior: 'smooth',
								WebkitOverflowScrolling: 'touch'
							}}
						>
							{children}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const LayoutHeader = () => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const router = useRouter();

	const [open, setOpen] = useState(false);
	const { status, data } = useSigninCheck();
	const connected = status === 'success' ? data.signedIn : false;
	const environment = process.env.NEXT_PUBLIC_ENV || 'production';
	const logoSrc =
		environment === 'staging'
			? '/static/icons/brand/logo_green.svg'
			: router.asPath === '/about-us' || router.asPath === '/functionalities'
			? // Utilisez le logo vert en staging
			  '/static/icons/brand/logo_dark.svg'
			: '/static/icons/brand/logo_white.svg'; // Utilisez le logo blanc en production

	const listMenu = [
		{
			title: t`Nous contacter`,
			id: 'contact',
			url: '/contact'
		},
		{
			title: t`Qui-sommes-nous ?`,
			id: 'AboutUs',
			url: '/about-us'
		},
		{
			title: t`Fonctionnalités`,
			id: 'Functionalities',
			url: '/functionalities'
		}
	];

	const listMenuMobile = [
		{
			title: t`Contact`,
			id: 'contact',
			url: '/contact'
		},
		{
			title: t`Qui-sommes-nous ?`,
			id: 'aboutUs',
			url: '/about-us'
		},
		{
			title: t`Fonctionnalités`,
			id: 'Functionalities',
			url: '/functionalities'
		}
	];

	return (
		<>
			<Box
				sx={{
					zIndex: 15,
					display: 'flex',
					justifyContent: 'space-around'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						position: 'absolute',
						width: '90%',
						top: '2vh',
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<Box
						component={Link}
						sx={{
							display: {
								md: 'block',
								xs: 'none'
							}
						}}
						href={'/'}
					>
						<Box
							alt="FreewayTeam"
							component="img"
							id="logo_home"
							src={logoSrc}
							sx={{
								height: 'auto',
								width: 200
							}}
						/>
					</Box>
					<Box
						component={Link}
						sx={{
							mt: 1,
							ml: 1,
							display: {
								md: 'none',
								xs: 'block'
							}
						}}
						href={'/'}
					>
						<Box
							alt="FreewayTeam"
							component="img"
							id="logo_home"
							src={logoSrc}
							sx={{
								height: 'auto',
								width: 100
							}}
						/>
					</Box>
					{isMobile || isMd ? (
						open ? (
							<ClearIcon
								onClick={() => setOpen(!open)}
								sx={{
									position: 'absolute',
									right: '2vw',

									top: '1vh',
									height: 'auto',
									width: 24
								}}
							/>
						) : (
							<>
								<MenuIcon
									onClick={() => setOpen(!open)}
									sx={{
										position: 'absolute',
										right: '2vw',
										color:
											router.asPath === '/'
												? '#FFF'
												: router.asPath === '/about-us' || router.asPath === '/functionalities'
												? '#212529'
												: '#FFF',

										top: '1vh',
										height: 'auto',
										width: 24
									}}
								/>
							</>
						)
					) : (
						<>
							<Box
								sx={{
									ml: 5,
									flexDirection: 'row',
									display: 'flex',

									cursor: 'pointer',
									zIndex: (theme) => theme.zIndex.appBar
								}}
							>
								{listMenu.map((element) => (
									<Link style={{ textDecoration: 'none' }} href={element.url} key={element.id}>
										<Typography
											variant={
												(router.asPath === '/about-us' && router.asPath === element.url) ||
												(router.asPath === '/functionalities' && router.asPath === element.url) ||
												(router.asPath === '/contact' && router.asPath === element.url)
													? 'h1'
													: 'body1'
											}
											sx={{
												textDecoration: 'none',
												color:
													router.asPath === element.url
														? (theme) => theme.palette.primary.main
														: router.asPath === '/about-us' || router.asPath === '/functionalities'
														? '#000'
														: '#FFF',
												fontWeight: 400,
												mr: (theme) => theme.spacing(3),
												fontSize: '16px !important',
												fontFamily: 'Inter, sans-serif !important'
											}}
										>
											{element.title}
										</Typography>
									</Link>
								))}
							</Box>

							<Box sx={{ position: 'absolute', right: '2vw' }}>
								{connected ? (
									<Link id="go_to_city" href="/city">
										<Button
											color={router.asPath === '/' ? 'inherit' : 'primary'}
											size="medium"
											sx={{
												'&:hover': {
													textDecoration: 'underline'
												}
											}}
											type="submit"
											variant="contained"
										>
											{t`ALLER SUR MA VILLE`}
										</Button>
									</Link>
								) : (
									<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<FormSignIn status={status} />
									</Box>
								)}
							</Box>
						</>
					)}
				</Box>
			</Box>
			{open ? (
				<Box
					sx={{
						background: '#212529',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'end',
						mr: (theme) => theme.spacing(3),
						mt: '10vh'
					}}
				>
					{listMenuMobile.map((element) => (
						<Link style={{ textDecoration: 'none', marginTop: 3 }} href={element.url} key={element.id}>
							<Typography
								onClick={() => setOpen(false)}
								sx={{
									textDecoration: 'none',
									color: router.asPath === element.url ? '#FFF' : '#fff',
									fontWeight: 400,
									fontSize: 16
								}}
							>
								{element.title}
							</Typography>
						</Link>
					))}
					{connected ? (
						<Link id="go_to_city" href="/city">
							<Button
								color="primary"
								size="medium"
								sx={{
									mb: 2,
									'&:hover': {
										textDecoration: 'underline'
									},
									mt: 3
								}}
								type="submit"
								variant="contained"
							>
								{t`ALLER SUR MA VILLE`}
							</Button>
						</Link>
					) : (
						<FormSignIn />
					)}
				</Box>
			) : (
				''
			)}
		</>
	);
};

export default OnboardingLayout;
